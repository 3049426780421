<template>
  <div class="columns">
    <div class="column" v-if="right || left">
      <div class="columns">
        <div class="column is-6">
          <ScatterGraph :plots="left" v-if="left" />
        </div>
        <div class="column is-6">
          <ScatterGraph :plots="right" v-if="right" />
        </div>
      </div>
    </div>
    <div class="column" v-else>
      <TableEmpty text="No datapoints found for your search" size="medium" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pounds, number } from 'core/utils/format'
export default {
  name: 'ExplorerGraphs',
  components: {
    ScatterGraph: () => import('core/components/graph/ScatterGraph'),
    TableEmpty: () => import('core/components/TableEmpty')
  },
  computed: {
    ...mapGetters('explorer', ['series', 'mileageSeries', 'indexedListings']),
    toolbar() {
      return { tools: { download: false } }
    },
    grid() {
      return {
        padding: { top: 0, right: 40, bottom: 20, left: 20 },
        borderColor: '#CCC',
        strokeDashArray: 2,
        position: 'back'
      }
    },
    fontFamily() {
      return 'proxima-nova, roboto'
    },
    gridLabels() {
      return {
        style: {
          cssClass: 'is-size-7 has-text-weight-bold',
          fontFamily: this.fontFamily
        }
      }
    },
    title() {
      return {
        offsetY: 20,
        style: {
          fontSize: '14px',
          fontFamily: this.fontFamily,
          cssClass: 'has-text-weight-semibold'
        }
      }
    },
    xaxis() {
      return { labels: this.gridLabels, axisBorder: { show: false } }
    },
    yaxis() {
      return {
        labels: { ...this.gridLabels, formatter: val => pounds(val) },
        title: { ...this.title, text: 'Price' }
      }
    },
    left() {
      const { series, fontFamily, toolbar, grid, yaxis, xaxis } = this

      if (!series) return null
      return {
        series: [{ name: 'Price', data: this.mapSeries(series, 'timestamp') }],
        options: {
          chart: { toolbar, events: this.getEvents('series') },
          tooltip: {
            style: { fontSize: '12px', fontFamily },
            x: { show: true, format: 'dd MMM yyyy' },
            y: { title: { formatter: seriesName => seriesName } },
            marker: { show: false }
          },
          grid,
          xaxis: {
            ...xaxis,
            type: 'datetime',
            title: { ...this.title, text: 'Year' }
          },
          yaxis
        }
      }
    },
    right() {
      const { mileageSeries, fontFamily, toolbar, grid, yaxis, xaxis } = this

      if (!mileageSeries) return null
      return {
        series: [
          { name: 'Price', data: this.mapSeries(mileageSeries, 'mileage') }
        ],
        options: {
          chart: { toolbar, events: this.getEvents('mileageSeries') },
          tooltip: {
            style: { fontSize: '12px', fontFamily },
            x: { formatter: val => `${number(val)} miles` },
            y: { title: { formatter: seriesName => seriesName } },
            marker: { show: false }
          },
          grid,
          xaxis: {
            ...xaxis,
            type: 'numeric',
            formatter: val => number(val),
            title: { ...this.title, text: 'Mileage' }
          },
          yaxis
        }
      }
    }
  },
  methods: {
    mapSeries(series, xValue) {
      return series.map(l => {
        return { x: l[xValue], y: l.price, fillColor: l.fillColor }
      })
    },
    openCard(series, index) {
      const listing = this.indexedListings[this[series][index].originalIndex]
      this.$modal.open('explorer/ExplorerListing', listing)
    },
    getEvents(series) {
      return {
        dataPointSelection: (event, chartContext, config) => {
          this.openCard(series, config.dataPointIndex)
        }
      }
    }
  }
}
</script>
